import I18n from 'common/i18n';
import { FieldSetT, FieldT, MetadataTemplate } from 'common/types/metadataTemplate';
import React, { useState } from 'react';
import { option } from 'ts-option';
import { AddNewNamedEntity } from './AddNewNamedEntity';
import { fieldChoiceDragDrop } from './FieldChoice';
import FieldsetDeletionButton from './FieldsetDeletionButton';
import './fieldsets.scss';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';
import DragDropContainer, { DragDropContainerType } from 'common/components/DragDropContainer';
const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates', ...options });

export interface CustomFieldChooserProps {
  busy: boolean;
  fieldset: FieldSetT;
  template: MetadataTemplate;
  onChooseField: (f: FieldT) => void;
  onDeleteFieldset: (fieldset: FieldSetT) => void;
  onAddField: (fieldset: FieldSetT, fieldDisplayName: string) => void;
  onReorderFields: (template: MetadataTemplate, fieldset: FieldSetT) => void;
  isSelected: (f: FieldT) => boolean;
}

export const CustomFieldChooser: React.FunctionComponent<CustomFieldChooserProps> = ({
  busy,
  fieldset,
  template,
  onChooseField,
  onAddField,
  onDeleteFieldset,
  isSelected,
  onReorderFields
}) => {
  const [showAddDialog, setShowAddDialog] = useState(false);

  const validateName = (name: string) =>
    option(fieldset.fields.find((f) => f.display_name.trim() === name.trim())).map(() => t('field_name_taken'));
  const onAdd = (displayName: string) => onAddField(fieldset, displayName);

  const dndFieldChoices = fieldset.fields.map((f) => (
    fieldChoiceDragDrop({
      field: f,
      qualifier: fieldset.fieldset_qualifier,
      isSelected: isSelected(f),
      onChooseField: () => onChooseField(f)
    })
  ));
  const onChangeOrder = (newItems: any) => {
    const newFieldset = {
      ...fieldset,
      fields: newItems
    };
    onReorderFields(template, newFieldset);
  };
  return (
    <div>
      <DragDropContainer
        type={DragDropContainerType.FORGE_LIST}
        items={fieldset.fields}
        className="reorder-fields-container"
        onDrop={onChangeOrder}
        dense={false}
        useHoverColor={false}
        childElements={dndFieldChoices}
      />
      <div className="fieldset-chooser-buttons">
        <ForgeButton>
          <button
            type="button"
            id="add-field-button"
            data-testid="add-field-button"
            onClick={() => setShowAddDialog(true)}
          >
            <ForgeIcon external external-type="standard" name="add_circle" />
            <span>{t('new_field')}</span>
          </button>
        </ForgeButton>
        <FieldsetDeletionButton
          template={template}
          deleteFieldset={() => onDeleteFieldset(fieldset)}
          fieldset={fieldset}
        />
        <AddNewNamedEntity
          onHide={() => setShowAddDialog(false)}
          busy={busy}
          buttonLabel={t('field_name_body')}
          validateName={validateName}
          placeholder={t('field_name')}
          label={t('new_field_alt')}
          onAdd={onAdd}
          dialogOpen={showAddDialog}
        />
      </div>
    </div>
  );
};
