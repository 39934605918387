import React from 'react';
import {
  ForgeScaffold,
  ForgeButton,
  ForgeIcon,
  ForgeButtonToggleGroup,
  ForgeButtonToggle
} from '@tylertech/forge-react';
import { omit as _omit, clone as _clone} from 'lodash';
import I18n from 'common/i18n';
import { SortOrder, alphabetize, determineAlphabeticalSortOrder, discoverDuplicates, getLabel, getOptionFromLabelOption } from './helpers';
import EnumerationChoice from './EnumerationChoice';
import { Expr, FunCall, Let, SoQLType, TableQualifier } from 'common/types/soql';
import { FieldT, labelOption } from 'common/types/metadataTemplate';
import { buildDefaultParentlessSelect } from 'common/dsmapi/metadataTemplate';

const t = (translationKey: string, options: { [key: string]: any } = {}) =>
  I18n.t(translationKey, { scope: 'metadata_templates', ...options });

// does lodash really not offer an immutable replaceAt function?
function replaceAtIndex<T>(things: T[], thing: T, index: number) {
  return things.map((existing, i) => (i === index ? thing : existing));
}

// When we get rid of the feature flag we'll want to just move the contents of the "new" editor back down into the main one.
interface NewSelectEditorProps {
  onAddOption: (newOptionValue?: string) => void;
  onChangeOption: (index: number) => (event: React.FormEvent<HTMLInputElement>) => void;
  onChangeLabel: (index: number) => (event: React.FormEvent<HTMLInputElement>) => void;
  onRemoveOption: (indexToRemove: number) => () => void;
  onAlphabetize: (ascending?: boolean) => void;
  alphabeticalSortOrder: SortOrder;
  options: string[];
  field: FieldT;
  withLabels: boolean;
}

const NewSelectEditor: React.FunctionComponent<NewSelectEditorProps> = ({
  onAddOption,
  onChangeOption,
  onChangeLabel,
  onRemoveOption,
  onAlphabetize,
  alphabeticalSortOrder,
  options,
  field,
  withLabels
}) => {
  const showSortToggle = options.length > 1;
  const duplicateOptions = discoverDuplicates(options);

  const optionsList = options.map((choice: string, index: number) => {
    let isDeletable = true;

    // As mentioned above, the first option in the list is a special case and needs to act differently if its the only one.
    if (options.length == 1 && choice.length == 0) {
      // There's no value in the option and it's the only one on screen. Don't show the delete button.
      isDeletable = false;
    }

    return (
      <EnumerationChoice
        key={index}
        isDuplicate={duplicateOptions[choice] || false}
        withLabels={withLabels}
        field={field}
        choice={choice}
        onChangeOption={onChangeOption(index)}
        onChangeLabel={onChangeLabel(index)}
        onRemoveOption={onRemoveOption(index)}
        index={index}
        isDeletable={isDeletable}
      />
    );
  });

  return (
    <div className="metadata-editor">
      <ForgeScaffold>
        <div className="metadata-list-header" slot="header">
          <h6>{t('dropdown_options_header')}</h6>
          {showSortToggle && (
            <ForgeButtonToggleGroup dense value={alphabeticalSortOrder}>
              <ForgeButtonToggle
                onClick={() => onAlphabetize(true)}
                data-testid="sort-alphabetical-ascending-button"
                aria-label={t('sort_options_ascending_button_label')}
                value={SortOrder.ASCENDING}
              >
                <ForgeIcon name="sort_alphabetical_ascending" />
              </ForgeButtonToggle>
              <ForgeButtonToggle
                onClick={() => onAlphabetize(false)}
                data-testid="sort-alphabetical-descending-button"
                aria-label={t('sort_options_descending_button_label')}
                value={SortOrder.DESCENDING}
              >
                <ForgeIcon name="sort_alphabetical_descending" />
              </ForgeButtonToggle>
            </ForgeButtonToggleGroup>
          )}
        </div>
        <div className="metadata-list" slot="body">
          {optionsList}
        </div>
        <div className="metadata-list-footer" slot="footer">
          <ForgeButton>
            <button type="button" onClick={() => onAddOption()} data-testid="add-new-option-button">
              <span>{t('add_option')}</span>
              <ForgeIcon name="add_circle" />
            </button>
          </ForgeButton>
        </div>
      </ForgeScaffold>
    </div>
  );
};

export interface SelectEditorProps {
  field: FieldT;
  qualifier: TableQualifier;
  updateExpr: ({
    expr,
    newLabels,
    newType
  }: {
    expr: Expr;
    newLabels?: labelOption[];
    newType?: SoQLType;
  }) => void;
  updateLegacyLabels: (optionsLabels: labelOption[]) => void;
  makeNewCaseEnumeration: (newOptions: string[]) => FunCall | Let;
  withLabels: boolean;
  options: string[];
}

const SelectEditor: React.FunctionComponent<SelectEditorProps> = ({
  field,
  qualifier,
  updateExpr,
  updateLegacyLabels,
  makeNewCaseEnumeration,
  withLabels,
  options
}) => {
  const onAddOption = (newOptionValue = '') => {
    const newLabels: labelOption[] = [...field.labels_options, [newOptionValue, null]];
    updateExpr({ expr: makeNewCaseEnumeration([...options, newOptionValue]), newLabels });
  };

  const onRemoveOption = (indexToRemove: number) => () => {
    if (indexToRemove < 0) return;

    // If we're removing the only object, just reset the whole expression
    if (options.length === 1) {
      updateExpr({
        expr: buildDefaultParentlessSelect(field.field_name, field.display_name, qualifier),
        newLabels: []
      });
    } else {
      const newOptions = options.filter((opt, i) => i !== indexToRemove);
      const newLabels = [...field.labels_options.slice(0, indexToRemove), ...field.labels_options.slice(indexToRemove + 1)];
      updateExpr({ expr: makeNewCaseEnumeration(newOptions), newLabels });
    }
  };

  const onChangeOption = (index: number) => (e: React.FormEvent<HTMLInputElement>) => {
    const newOptions = replaceAtIndex(options, e.currentTarget.value, index);
    const newLabels = _clone(field.labels_options);
    newLabels[index] = [e.currentTarget.value, getLabel(field.labels_options, index)];
    updateExpr({ expr: makeNewCaseEnumeration(newOptions), newLabels });
  };

  const onChangeLabel = (index: number) => (e: React.FormEvent<HTMLInputElement>) => {
    const newLabels: labelOption[] = replaceAtIndex(
      field.labels_options,
      [getOptionFromLabelOption(field.labels_options, index), e.currentTarget.value],
      index
    );
    updateLegacyLabels(newLabels);
  };

  const onAlphabetize = (ascending = true) => {
    const orderedLabelOptions = alphabetize(options, field.labels_options, ascending);
    const orderedOptions = orderedLabelOptions.map(([opt]) => opt);
    updateExpr({ expr: makeNewCaseEnumeration(orderedOptions), newLabels: orderedLabelOptions });
  };

  const alphabeticalSortOrder = determineAlphabeticalSortOrder(options);

  return (
    <NewSelectEditor
      onAddOption={onAddOption}
      onChangeOption={onChangeOption}
      onChangeLabel={onChangeLabel}
      onRemoveOption={onRemoveOption}
      onAlphabetize={onAlphabetize}
      alphabeticalSortOrder={alphabeticalSortOrder}
      options={options}
      field={field}
      withLabels={withLabels}
    />
  );
};

export default SelectEditor;
