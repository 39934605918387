import Button, { SIZES, VARIANTS } from 'common/components/Button';
import ConfirmationModal from 'common/components/ConfirmationDialog/ConfirmationModal';
import { Checkbox, DropdownWithLabel, Form, Input } from 'common/components/Forms';
import SortFilterResultsTable, { Column } from 'common/components/SortFilterResultsTable';
import I18n from 'common/i18n';
import { includes } from 'lodash';
import React, { useEffect, useState } from 'react';
import { none, option, Option, some } from 'ts-option';
import { Category } from 'common/core/categories';
import * as Api from 'common/core/categories';
import './categories.scss';
const t = (k: string, options: { [key: string]: any } = {}) => I18n.t(k, { scope: 'metadata_templates', ...options });

interface AddCategoryProps {
  categories: Category[];
  configId: Option<number>;
  setCategories: (cats: Category[]) => void;
  setConfigId: (id: Option<number>) => void;
}
const AddCategory: React.FunctionComponent<AddCategoryProps> = ({ categories, configId, setCategories, setConfigId }: AddCategoryProps) => {
  const [name, setName] = useState('');
  const [parent, setParent] = useState<string | null>(null);
  const [enabled, setEnabled] = useState<boolean>(true);
  const {error, inProgress, runCoreRequest} = useCoreRequest();


  const onAddNewCategory = (cat: Category) => {
    configId.match({
      some: async (cid) => {
        (await runCoreRequest(() => Api.createCategory(cid, cat))).map((newCat) => {
          setCategories([...categories, newCat]);
        });
      },
      none: async () => {
        (await runCoreRequest(() => Api.createCategories([...categories, cat]))).map((newConfig) => {
          // since it's from core, maybe the key doesn't exist, maybe it does. who knows.
          setCategories(newConfig.properties || []);
          setConfigId(option(newConfig.id));
        });
      }
    });
};

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onAddNewCategory({
      name,
      value: { parent, enabled }
    });
    return false;
  };
  return (
    <div className="add-categories">
      <Form className="add-category-form" onSubmit={onSubmit}>
        <legend>{t('add_category')}</legend>
        {
          error.map((message) => (
            <div className="alert error">
              {message}
            </div>
          )).getOrElseValue(<span></span>)
        }
        <div className="add-category-attributes">
          <Input label={t('category_name')} required={true} value={name} onChange={(e) => setName(e.currentTarget.value) } />
          <DropdownWithLabel
            dropdownLabel={t('parent')}
            handleSelection={({ value }) => setParent(value)}
            value={parent}
            labelContent={t('parent')}
            options={categories.map(cat => ({
              value: cat.name, title: cat.name
            }))} />
          <Checkbox
            label={t('enabled')}
            checked={true}
            defaultChecked={true}
            onChange={(e) => setEnabled(e.currentTarget.checked)}
          />
        </div>

        <div className="add-category-buttons">
          <Button busy={inProgress} disabled={name.length <= 0} type="submit" className="save" size={SIZES.SMALL} variant={VARIANTS.PRIMARY}>
            {I18n.t('core.dialogs.save')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

const searchPredicate = (term: string, cat: Category) => (
  includes(cat.name.toLowerCase(), term.toLowerCase())
);

enum CategorySortKey {
  Name = 'name',
  Parent = 'parent',
  Enabled = 'enabled',
}

const mapSortKeyToValue = (k: CategorySortKey) => (cat: Category) => {
  switch (k) {
    case CategorySortKey.Name:
      return cat.name;
    case CategorySortKey.Parent:
      return cat.value.parent || '';
    case CategorySortKey.Enabled:
      return cat.value.enabled ? 1 : -1;
  }
};


function useCoreRequest() {
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState<Option<string>>(none);
  const wrapper = async <T,>(executor: () => Promise<T>): Promise<Option<T>> => {
    setInProgress(true);
    try {
      const res: T = await executor();
      setInProgress(false);
      setError(none);
      return some(res);
    } catch (e) {
      console.error(e);
      const js = await e.response.json();
      setError(option(js.message));
      setInProgress(false);
      return none;
    }
  };
  return {error, inProgress, runCoreRequest: wrapper};
}

const Categories: React.FunctionComponent = () => {
  const [configId, setConfigId] = useState(none as Option<number>);
  const [categories, setCategories] = useState([] as Category[]);
  const {error, inProgress, runCoreRequest} = useCoreRequest();
  const [deletingCategory, setDeletingCategory] = useState<Option<Category>>(none);

  useEffect(() => {
    // this is a stupid extra wrapping because useEffect wants a cleanup fun.
    // wow so elegant
    const doit = async () => {
      const config = await Api.getCategories();
      // stupid core turning empty lists into undefined GAHhah
      // who decided that was a good idea
      setCategories(config.properties || []);
      setConfigId(option(config.id));
    };
    doit();
  }, []);

  const onDeleteCategory = (toDelete: Category) => {
    const withoutDeleted = categories.filter(c => c.name !== toDelete.name);
    configId.match({
      some: async (cid) => {
        (await runCoreRequest(() => Api.deleteCategory(cid, toDelete))).forEach(() => {
          setCategories(withoutDeleted);
          setDeletingCategory(none);
        });
      },
      none: async () => {
        (await runCoreRequest(() => Api.createCategories(withoutDeleted))).forEach(() => {
          setCategories(withoutDeleted);
          setDeletingCategory(none);
        });
      }
    });
  };

  const onToggleCategory = (cat: Category, enabled: boolean) => {
    const updatedCategory = {...cat, value: {...cat.value, enabled}};
    const updated = categories.map(c => (
      c.name === cat.name ? updatedCategory : c
    ));
    configId.match({
      some: async (cid) => {
        (await runCoreRequest(() => Api.updateCategory(cid, updatedCategory))).forEach(() => {
          setCategories(updated);
        });
      },
      none: async () => {
        (await runCoreRequest(() => Api.createCategories(updated))).forEach(() => {
          setCategories(updated);
        });
      }
    });
  };

  return (
    <div className="categories">
      {
        error.map((message) => (
          <div className="alert error">
            {message}
          </div>
        )).getOrElseValue(<span></span>)
      }

      <SortFilterResultsTable
        items={categories}
        searchPlaceholder={t('search_categories')}
        searchPredicate={searchPredicate}
        rowKey={'name'}
        mapSortKeyToValue={mapSortKeyToValue}
        rowClassFunction={() => 'category'}
        id={'categories'}
        noResultsMessage={t('no_categories')}
        defaultSort={{
          by: CategorySortKey.Name,
          direction: 'ASC'
        }}
        >
        <Column<Category, CategorySortKey.Name>
          header={t('category_name')}
          sortKey={CategorySortKey.Name}
          render={(cat) => (
            <div>
              {cat.name}
            </div>
          )}
        />
        <Column<Category, CategorySortKey.Parent>
          header={t('parent')}
          sortKey={CategorySortKey.Parent}
          render={(cat) => (
            <div>
              {cat.value.parent || ''}
            </div>
          )}
        />
        <Column<Category, CategorySortKey.Enabled>
          header={t('is_shown')}
          sortKey={CategorySortKey.Enabled}
          render={(cat) => (
            <div>
              <Checkbox
                key={cat.name}
                id={cat.name}
                checked={cat.value.enabled}
                defaultChecked={cat.value.enabled}
                label={t(cat.value.enabled ? 'category_enabled' : 'category_disabled')}
                disabled={inProgress}

                onChange={(e) => onToggleCategory(cat, e.currentTarget.checked)}
              />
            </div>
          )}
        />
        <Column<Category, CategorySortKey.Enabled>
          header={t('delete_category')}
          render={(cat) => (
            <div>
              <Button
                busy={inProgress}
                size={SIZES.SMALL}
                onClick={() => setDeletingCategory(some(cat))}>
                {t('delete_category')}
              </Button>
            </div>
          )}
        />
      </SortFilterResultsTable>
      <AddCategory categories={categories} setCategories={setCategories} setConfigId={setConfigId} configId={configId} />
      {
        deletingCategory.map((cat) => (
          <ConfirmationModal
            onAgree={() => onDeleteCategory(cat)}
            onCancel={() => setDeletingCategory(none)}
            headerText={t('really_delete_category', cat)}
          >
            {t('really_delete_body')}
          </ConfirmationModal>
        )).getOrElseValue(<div></div>)
      }
    </div>
  );
};

export default Categories;
