import I18n from 'common/i18n';
import { FieldT, MetadataTemplate } from 'common/types/metadataTemplate';
import { TableQualifier } from 'common/types/soql';
import { ActionTypes, Dispatcher } from 'metadataTemplates/store';
import React from 'react';
import { connect } from 'react-redux';
import { ForgeCheckbox } from '@tylertech/forge-react';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates', ...options });

export const RestrictednessEditor: React.FunctionComponent<Props> = ({ field, busy, updateRestrictedness }) => {

  if (!field.is_builtin) {
    return (
      <ForgeCheckbox >
        <input
          type="checkbox"
          id="checkbox-field"
          data-testid='field-editor-restricted'
          checked={field.restricted}
          disabled={busy}
          onChange={() => {}}
          onClick={(event: any) => updateRestrictedness(event.target.checked)}
        />
        <label htmlFor="checkbox-field" slot="label">{t('restrict')}</label>
      </ForgeCheckbox>
    );
  }
  return null;
};

interface ExternalProps {
  template: MetadataTemplate;
  qualifier: TableQualifier;
  field: FieldT;
  busy: boolean;
}
interface DispatchProps {
  updateRestrictedness: (isRestricted: boolean) => void;
}

type Props = ExternalProps & DispatchProps;

const mapDispatchToProps = (dispatch: Dispatcher, extProps: ExternalProps) => {
  return {
    updateRestrictedness: (isRestricted: boolean) => {
      dispatch({
        type: ActionTypes.FieldRestrictedChanged,
        templateName: extProps.template.name,
        qualifier: extProps.qualifier,
        field: extProps.field,
        isRestricted
      });
    }
  };
};

export default connect(null, mapDispatchToProps)(RestrictednessEditor);
