import ModalWrapper from 'common/components/AccessibleModal/ModalWrapper';
import { AccordionContainer, AccordionPane } from 'common/components/Accordion';
import AssetBrowser from 'common/components/AssetBrowser';
import * as constants from 'common/components/AssetBrowser/lib/constants';
import { RENDER_STYLE_LIST } from 'common/components/AssetBrowser/lib/constants';
import { mergedCeteraQueryParameters } from 'common/components/AssetBrowser/lib/helpers/cetera';
import ceteraUtils from 'common/cetera/utils';
import * as ceteraHelpers from 'common/components/AssetBrowser/lib/helpers/cetera';
import { getErrorMessage, isErrorCell } from 'common/components/DatasetTable/cell/TableCell';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { fieldDisplayName } from 'common/dsmapi/metadataTemplate';
import I18n from 'common/i18n';
import { PhxChannel } from 'common/types/dsmapi';
import { MetadataTemplate, TemplateFieldResult, TemplateResult } from 'common/types/metadataTemplate';
import _, { isUndefined, partition, orderBy } from 'lodash';
import { AppState } from 'metadataTemplates/store';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { none, Option, option, some } from 'ts-option';
import './audit.scss';
import AuditExport from './AuditExport';
import { TemplateChannelTimeout } from 'metadataTemplates/util';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates', ...options });

interface PendingAuditJob {
  type: 'pending';
  dataset: DatasetDetails;
}
interface CompleteAuditJob {
  type: 'complete';
  dataset: DatasetDetails;
  result: TemplateResult[];
}
interface ErrorAuditJob {
  dataset: DatasetDetails;
  type: 'error';
  reason: string;
}
type AuditJob = PendingAuditJob | CompleteAuditJob | ErrorAuditJob;
type AuditJobs = Record<string, AuditJob>;

type ErrorAuditJobResult = Omit<ErrorAuditJob, 'dataset'> & { fourfour: string };
type CompleteAuditJobResult = Omit<CompleteAuditJob, 'dataset'> & { fourfour: string };
const pendingJob = (dataset: DatasetDetails): PendingAuditJob => ({ type: 'pending', dataset });

const countIssues = (templateResults: TemplateResult[]) =>
  templateResults.reduce((acc, tr) => {
    return tr.result.reduce(
      (count, fieldResult) => (isErrorCell(fieldResult.results[0]) ? count + 1 : count),
      acc
    );
  }, 0);

interface CeteraResult {
  resource: {
    name: string;
    id: string;
  };
  link: string;
}

const FieldAuditResult = ({
  fieldResult,
  template
}: {
  fieldResult: TemplateFieldResult;
  template: MetadataTemplate;
}) => {
  const errorResult = isErrorCell(fieldResult.results[0]) ? option(fieldResult.results[0]) : none;
  // yea this could be written as one big expr but it got confusing
  let displayName = fieldResult.name.field_name;
  if (fieldResult.name.qualifier) {
    const fieldSet = option(
      template.custom_fields.find((fs) => fs.fieldset_qualifier === fieldResult.name.qualifier)
    );
    const customField = fieldSet.flatMap((fs) =>
      option(fs.fields.find((field) => field.field_name === fieldResult.name.field_name))
    );

    fieldSet.forEach((fs) =>
      customField.forEach((field) => {
        displayName = `${fs.fieldset_name}: ${field.display_name}`;
      })
    );
  } else {
    option(template.builtin_fields.find((f) => f.field_name === fieldResult.name.field_name)).forEach(
      (templateField) => {
        displayName = fieldDisplayName(templateField);
      }
    );
  }

  return (
    <div className="field-audit-result">
      <div>
        {errorResult.match({
          some: () => <SocrataIcon name={IconName.Warning} />,
          none: () => <SocrataIcon name={IconName.Check2} />
        })}
        <span>{displayName}</span>
      </div>
      {errorResult
        .map(getErrorMessage)
        .map((message) => <p className="audit-error-message">{message}</p>)
        .getOrElseValue(<span></span>)}
    </div>
  );
};

interface DatasetDetails {
  name: string;
  link: string;
  uid: string;
}

const AuditResultDetails = ({ job, templates, dismiss }: { job: CompleteAuditJob; templates: MetadataTemplate[], dismiss: () => void }) => {
  const issueCount = countIssues(job.result);

  const orderedFieldResults = (tr: TemplateResult) => {
    // this might be super slow one day
    const [builtins, customs] = partition(tr.result, (r) => r.name.qualifier === null);
    return orderBy(builtins, b => b.name.field_name).concat(
      orderBy(customs, c => c.name.qualifier + c.name.field_name)
    );
  };

  return (
    <div className="metadata-audit-details">
      <div className="metadata-audit-title">
        <legend>
          {t('metadata_report')}{' '}
          <a href={job.dataset.link} target="_blank">
            {job.dataset.name}
          </a>
        </legend>
        <a onClick={dismiss}><SocrataIcon name={IconName.Close} /></a>
      </div>
      <p>
        {t('audit_summary', {
          count: issueCount
        })}
      </p>
      <AccordionContainer>
        {job.result.map((tr) => (
          <AccordionPane key={tr.template_name} title={tr.template_name}>
            <div>
              {orderedFieldResults(tr).map((fieldResult) =>
                option(templates.find((template) => template.name === tr.template_name))
                  .map((template) => (
                    <FieldAuditResult
                      template={template}
                      key={`${tr.template_name}_${fieldResult.name.qualifier}_${fieldResult.name.field_name}`}
                      fieldResult={fieldResult}
                    />
                  ))
                  .getOrElseValue(<div></div>) // if we end up here, we failed to find the template...
              )}
            </div>
          </AccordionPane>
        ))}
      </AccordionContainer>
    </div>
  );
};

const AuditResult = ({ job, onClick }: { job: AuditJob; onClick: (job: CompleteAuditJob) => void }) => {
  if (isUndefined(job) || job.type === 'pending') return <span className="spinner-default" />;
  if (job.type === 'error') {
    return (
      <div className="audit-result audit-error">
        <SocrataIcon name={IconName.Failed} />
      </div>
    );
  }

  const issueCount = countIssues(job.result);
  if (issueCount > 0) {
    return (
      <div className="audit-result audit-warning">
        <SocrataIcon name={IconName.Warning} />
        <a onClick={() => onClick(job)}>{t('issues', { issueCount, count: issueCount })}</a>
      </div>
    );
  } else {
    return (
      <div className="audit-result audit-success">
        <SocrataIcon name={IconName.Check2} />
      </div>
    );
  }
};

// no clue why cetera mangles the fourfour. it does things like four-four:draft despite the fact that
// other attributes exist to tell draftiness and other states. ugh. why.
const ceteraIdToFourFour = (ceteraUid: string) => ceteraUid.split(':')[0];
const modalStyle = {
  maxWidth: '75vw',
  position: 'absolute',
  left: '12.5vw',
  zIndex: 3,
  top: '80px',
  bottom: '40px',
  border: '1px solid #ccc',
  background: '#fff',
  overflow: 'auto',
  WebkitOverflowScrolling: 'touch',
  borderRadius: '4px',
  outline: 'none',
  padding: '20px'
};
const overlayStyle = {
  zIndex: '4',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.75)'
};

const Audit: React.FunctionComponent<StateProps> = ({ chan, templates }) => {
  const forUser = ceteraHelpers.getCurrentUserId();
  const [auditJobs, setAuditJobs] = useState<AuditJobs>({});
  const [focusedResult, setFocusedResult] = useState<Option<CompleteAuditJob>>(none);

  const onAssetsChanged = ({ results }: { results: CeteraResult[] }) => {
    const datasets: DatasetDetails[] = results.map((r) => ({
      link: r.link,
      name: r.resource.name,
      uid: ceteraIdToFourFour(r.resource.id)
    }));

    const newState = datasets.reduce(
      (acc, dataset) => ({ ...acc, [dataset.uid]: pendingJob(dataset) }),
      auditJobs
    );
    setAuditJobs(newState);

    chan.push('audit', { fourfours: datasets.map((d) => d.uid) }, TemplateChannelTimeout);
  };

  useEffect(() => {
    chan.on('audit_result', (res: ErrorAuditJobResult | CompleteAuditJobResult) => {
      setAuditJobs((state: AuditJobs) => {
        return option(state[res.fourfour]).flatMap(jr => option(jr.dataset)).match({
          some: (dataset) => {
            const jobResult: ErrorAuditJob | CompleteAuditJob =
            res.type === 'error'
              ? { type: 'error', dataset, reason: res.reason }
              : { type: 'complete', dataset, result: res.result };

            return {
              ...state,
              [res.fourfour]: jobResult
            };
          },
          none: () => state
        });
      });
    });

    return () => {
      chan.off('audit_result');
    };
  }, [chan]);

  const columns = [
    constants.COLUMN_TYPE,
    constants.COLUMN_NAME,
    constants.COLUMN_LAST_UPDATED_DATE,
    constants.COLUMN_OWNER,
    constants.COLUMN_AUDIENCE,
    {
      title: t('metadata_status'), // TODO: translate
      render: ({ uid }: { uid: string }) => {
        const fourfour = ceteraIdToFourFour(uid);
        return (
          <td key={uid}>
            <AuditResult job={auditJobs[fourfour]} onClick={(job) => setFocusedResult(some(job))} />
          </td>
        );
      }
    }
  ];

  const assetTypes = [
    'calendars',
    'charts',
    'datasets', // NOT including system_datasets here!
    'visualizations',
    'hrefs', // NOT including federated_hrefs here!
    'files',
    'filters',
    'forms',
    'maps',
    'measures',
    'stories'
  ];

  const mergeFilters = (...args: any[]) => {
    const filters = _.merge({}, ...args);
    // guh...asset browser stores this as a comma delimited string
    // for some reason, even though it goes through translateFiltersToQueryParameters
    // before being sent to catalong...weird.
    const desiredFilters = filters.assetTypes ? filters.assetTypes.split(',') : assetTypes;

    // we don't want to allow them to include certain things,
    // but we want to allow them to filter down from the set of assetTypes
    // listed above, so it's just a set intersection
    return {
      ...filters,
      assetTypes: _.intersection(assetTypes, desiredFilters).join(',')
    };
  };

  const tabs = {
    [constants.TAB_ALL_ASSETS]: {
      props: {
        columns,
        baseFilters: {
          source: window.socrata.domain
        }
      }
    },
    [constants.TAB_MY_ASSETS]: {
      props: {
        baseFilters: {
          forUser,
          source: window.socrata.domain
        },
        columns
      }
    }
  };

  // this "filters" thing is entirely dependent on ceteraUtils, which
  // is tied closely to cetera itself. At this point, let's make everything
  // a never so it's opaque and we make sure that code at this level can't
  // muck with it.
  const [filters, setFilters] = useState<never>({} as never);

  const assetBrowserProps = {
    initialTab: constants.TAB_ALL_ASSETS,
    renderStyle: RENDER_STYLE_LIST,
    showAssetCounts: true,
    showFilters: true,
    showHeader: true,
    showSearchField: true,
    enableAssetInventoryActions: false,
    additionalBottombarComponents: [
      <AuditExport key="exporter" chan={chan} filters={filters} />
    ],
    tabs,
    showSystemDatasets: false,
    allowedVersions: [
      constants.VERSION_PUBLISHED
    ],
    mergeFilters,
    middlewares: [
      (store: any) => (next: any) => (action: any) => {
        if (action.type === 'UPDATE_CATALOG_RESULTS') {
          onAssetsChanged(action.response);
        }
        setFilters(ceteraUtils.normalizeCeteraQueryParams(mergedCeteraQueryParameters(store.getState, {})));
        return next(action);
      }
    ]
  };
  const onDismiss = () => setFocusedResult(none);

  return (
    <div className="metadata-audit">
      <AssetBrowser {...assetBrowserProps} />
      {focusedResult
        .map((job) => (
          <ModalWrapper style={{content: modalStyle as any, overlay: overlayStyle as any}} onDismiss={onDismiss}>
            <AuditResultDetails templates={templates} job={job} dismiss={onDismiss}  />
          </ModalWrapper>
        ))
        .getOrElseValue(<span></span>)}
    </div>
  );
};

interface ExternalProps {}
interface StateProps {
  chan: PhxChannel;
  templates: MetadataTemplate[];
}

const mapStateToProps = (state: AppState, props: ExternalProps): StateProps => {
  return {
    chan: state.channel,
    templates: state.templateStates.map((ts) => ts.template)
  };
};
export default connect(mapStateToProps)(Audit);
