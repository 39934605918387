import I18n from 'common/i18n';
import React, { useState } from 'react';
import { none, Option, some } from 'ts-option';
import { ForgeButton, ForgeDialog, ForgeTextField, ForgeToolbar } from '@tylertech/forge-react';
import './fieldsets.scss';
const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates', ...options });

export interface AddNewNamedEntityProps {
  busy: boolean;
  label: string;
  buttonLabel: string;
  placeholder: string;
  onAdd: (name: string) => void;
  validateName: (name: string) => Option<string>;
  onHide: () => void;
  dialogOpen: boolean;
}
export const AddNewNamedEntity: React.FunctionComponent<AddNewNamedEntityProps> = ({
  label,
  busy,
  buttonLabel,
  placeholder,
  onAdd,
  onHide,
  validateName,
  dialogOpen
}) => {
  const [newName, setNewName] = useState<Option<string>>(none);
  const error = newName.flatMap(validateName);

  const onDismiss = () => {
    setNewName(none);
    onHide();
  };

  const dialogAttributes = new Map([
    ['aria-labelledby', 'new-field-title'],
    ['aria-describedby', 'new-field-set-label']
  ]);
  const characterCount = newName.isDefined ? newName.get.trim().length : 0;

  return (
    <ForgeDialog open={dialogOpen || false} options={{ dialogAttributes, backdropClose: false }} onDismiss={onDismiss}>
      <div className='add-fieldset-dialog'>
        <div className='add-fieldset-dialog-body'>
          <h1 id="new-field-title" data-testid="new-field-title" className='forge-typography--title forge-header-title'>{label}</h1>
          <p className="forge-typography--body2" id='new-field-set-label' data-testid='new-field-set-label'>{buttonLabel}</p>
          <ForgeTextField invalid={!error.isEmpty}>
            <input
              id="new-field-set-name"
              onChange={(e) => setNewName(some(e.target.value))}
              data-testid="add-new-named-entity-name-input"
            />
            <label htmlFor='new-field-set-name' data-testid="new-field-set-name-label">{placeholder}</label>
            {!error.isEmpty && <span className="helper-text-for-input-text" data-testid="new-entity-validation-message" slot="helper-text">{error.get}</span>}
          </ForgeTextField>
        </div>
        <ForgeToolbar inverted={true}>
          <span slot='end'>
            <ForgeButton type="outlined" className="cancel-fieldset-button">
              <button type='button' id='cancel-button' onClick={onDismiss} data-testid="add-new-named-entity-cancel-button">
                <span>{t('cancel')}</span>
              </button>
            </ForgeButton>
            <ForgeButton type="raised">
              <button
                type='button'
                id='create-entity-button'
                data-testid="add-new-named-entity-create-button"
                onClick={() => {
                  if (newName.isDefined) {
                    onAdd(newName.get);
                    setNewName(none);
                    onHide();
                  }
                }}
                disabled={newName.isEmpty || characterCount === 0 || !error.isEmpty || busy}
              >
                <span>{t('create')}</span>
              </button>
            </ForgeButton>
          </span>
        </ForgeToolbar>
      </div>
    </ForgeDialog>
  );
};
