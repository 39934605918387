import { PhxChannel } from 'common/types/dsmapi';
import React, { useEffect, useState } from 'react';
import Button, { VARIANTS } from 'common/components/Button';
import { some, none, Option } from 'ts-option';
import I18n from 'common/i18n';
import { Checkbox } from 'common/components/Forms';
import Dropdown, { DropdownWithLabel } from 'common/components/Forms/Dropdown';
const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates.audit_export', ...options });

// phoenix.js doens't accept null as a timeout, nor does it accept
// Infinity as a timeout. Infinity is what we really want. There *is*
// no timeout for this. So we'll just use a big number...meh.
const TIMEOUT = 1000 * 60 * 60 * 24 * 7;

interface Props {
  chan: PhxChannel;
  filters: never; // we treat this as an opaque term
}

interface AuditInitializing {
  type: 'initializing';
}
interface AuditStarted {
  type: 'started';
}
interface AuditInProgress {
  type: 'in_progress';
  fetched: number;
  result_size: number;
}
interface AuditDone {
  type: 'done';
}
type AuditProgress = AuditInitializing | AuditStarted | AuditInProgress | AuditDone;

function AuditExport({ chan, filters }: Props) {
  const [error, setError] = useState<Option<string>>(none);
  const [uri, setUri] = useState<Option<string>>(none);
  const [progress, setProgress] = useState<Option<AuditProgress>>(none);

  const onProgress = (newProgress: AuditProgress) => {
    setProgress(some(newProgress));
  };

  const onStart = ({ value: invalidOnly }: { value: 'all' | 'errors' }) => {
    if (progress.isDefined) return;

    setProgress(some({ type: 'initializing' }));
    chan.on('audit_progress', onProgress);

    const internalError = () => {
      setError(some(t('internal_error')));
      setProgress(none);
      setTimeout(() => {
        setError(none);
      }, 5000);
      chan.off('audit_progress');
    };
    chan
      .push('audit_catalog', { filters, invalid_only: invalidOnly === 'errors' }, TIMEOUT)
      .receive('timeout', internalError)
      .receive('ok', ({ uri: downloadLocation }: { uri: string }) => {
        setError(none);
        setProgress(none);
        setUri(some(downloadLocation));
        chan.off('audit_progress');
      })
      .receive('error', internalError);
  };

  if (uri.isDefined) {
    return (
      <Button variant={VARIANTS.SUCCESS} href={uri.get}>
        {t('download')}
      </Button>
    );
  }

  if (error.isDefined) {
    return <Button variant={VARIANTS.ERROR}>{error.get}</Button>;
  }

  if (progress.isDefined) {
    const p = progress.get;
    return (
      <Button variant={VARIANTS.ALTERNATE_2} disabled={true}>
        {t(p.type, p)}
      </Button>
    );
  }

  return (
    <Dropdown
      placeholder={t('export')}
      picklistSizingStrategy={'EXPAND_TO_WIDEST_ITEM'}
      handleSelection={onStart}
      options={[
        {
          title: t('export_all'),
          value: 'all'
        },
        {
          title: t('export_errors'),
          value: 'errors'
        }
      ]}
    />
  );
}

export default AuditExport;
