import { zip as _zip, orderBy as _orderBy, isUndefined as _isUndefined} from 'lodash';
import { FieldT, labelOption } from 'common/types/metadataTemplate';
import I18n from 'common/i18n';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates', ...options });

// does lodash really not offer an immutable replaceAt function?
export function replaceAtIndex<T>(things: T[], thing: T, index: number) {
  return things.map((existing, i) => (i === index ? thing : existing));
}

export enum SortOrder {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
  NOT_SORTED = 'not_sorted'
}

const checkSortOrder = (options: string[], sortOrder: SortOrder) => {
  const lowercaseOptions = options.map((option) => option.toLocaleLowerCase());

  return lowercaseOptions.reduce(
    (acc: { isOrdered: boolean; prev: string | null }, lit: string | null) => {
      // we don't care anymore
      if (!acc.isOrdered) return acc;

      const prev = acc.prev;
      if (prev && lit) {
        const isOrdered = sortOrder === SortOrder.ASCENDING ? prev < lit : prev > lit;
        return { isOrdered, prev: lit };
      }
      return { isOrdered: acc.isOrdered, prev: lit };
    },
    { isOrdered: true, prev: null }
  ).isOrdered;
};

export const determineAlphabeticalSortOrder = (options: string[]): SortOrder => {
  const ascending = checkSortOrder(options, SortOrder.ASCENDING);
  const descending = checkSortOrder(options, SortOrder.DESCENDING);

  if (!ascending && !descending) {
    return SortOrder.NOT_SORTED;
  }

  if (ascending) {
    return SortOrder.ASCENDING;
  }

  return SortOrder.DESCENDING;
};

export const errorMessage = (field: FieldT, options: string[]): string => {
  const maxOptionsShownInError = 20;
  let message = t('error_messages.must_be_one_of', {
    name: field.display_name,
    options: options
      .slice(0, maxOptionsShownInError)
      .map((sl) => sl)
      .join(', ')
  });

  if (options.length > maxOptionsShownInError) {
    message = `${message} ${t('error_messages.n_more_options', {
      count: options.length - maxOptionsShownInError
    })}`;
  }

  return message;
};

export const alphabetize = (options: string[], labelsOptions: labelOption[], ascending: boolean) => {
  const sortOrder = ascending ? 'asc' : 'desc';

  const paddedLabelOptions: labelOption[] = options.map((opt, i) => {
    const label = getLabel(labelsOptions, i);
    const paddedLabel = _isUndefined(label) ? null : label;
     return [opt, paddedLabel];
  });
  const orderedZip = _orderBy(paddedLabelOptions, ([opt]) => opt?.toLocaleLowerCase(), [sortOrder]);
  return orderedZip;
};

export const discoverDuplicates = (options: string[]) => {
  const tracker = {};
  const duplicates = {};
  options.forEach(opt => {
    if (tracker[opt]) {
      duplicates[opt] = true;
    } else {
      tracker[opt] = true;
    }
  });
  return duplicates;
};

export const getLabel = (labelOptions: labelOption[], index: number) => {
  return (labelOptions[index] || [])[1];
};

export const getOptionFromLabelOption = (labelOptions: labelOption[], index: number) => {
  return (labelOptions[index] || [])[0];
};
