import {
  ForgeButton,
  ForgeDialog,
  ForgeExpansionPanel,
  ForgeIcon,
  ForgeIconButton,
  ForgeScaffold,
  ForgeToolbar
} from '@tylertech/forge-react';
import React from 'react';
import FieldTester, { FieldTesterProps } from './FieldTester';
import './fieldValidation.scss';
import CompilerResult from 'common/components/CompilerResult';
import SoQLDocs from 'common/components/SoQLDocs';
import SoQLEditor, { Props as SoQLEditorProps } from 'common/components/SoQLEditor';
import { AppState, FieldCompilation } from 'metadataTemplates/store';
import { Option, none, some } from 'ts-option';
import I18n from 'common/i18n';
import ConfirmationModal from 'common/components/ConfirmationDialog/ConfirmationModal';
import { VARIANTS } from 'common/components/Button';
import { PhxChannel, TransformResult } from 'common/types/dsmapi';
import { FieldT, MetadataTemplate } from 'common/types/metadataTemplate';
import { TableQualifier, SoQLType, isExpressionEqualIgnoringPosition } from 'common/types/soql';
import { Evaluatable } from './FieldEditor';
import { Matcher } from 'common/components/SoQLDocs';
import { Scope } from 'common/types/soql';
import { connect } from 'react-redux';
import uuid from 'uuid';
import { TemplateChannelTimeout, typedUniqColumnRefs } from 'metadataTemplates/util';
import _ from 'lodash';
import { CompilationStatus } from 'common/types/compiler';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates.field_validation', ...options });

type Props = StateProps & ExternalProps;

interface StateProps {
  chan: PhxChannel;
}

export type ExternalProps = {
  onDismiss: () => void;
  compilationResult: Option<FieldCompilation>;
  field: FieldT;
  template: MetadataTemplate;
  qualifier: TableQualifier;
  updateInputType: (st: SoQLType) => void;
  enumeration: Option<string[]>;
  scope: Scope;
  matcher: Matcher;
  onSoQLChange: (soql: string) => void;
};
interface State {
  showSoqlValidation: boolean;
  soql: string | null;
  showUnappliedChangesDialog: boolean;
  draftCompilationResult: Option<FieldCompilation>;
  inputValues: Evaluatable;
  transformResult: TransformResult | null;
}

const debounceCompilation = _.debounce((what: () => unknown) => {
  what();
}, 250);

export class FieldValidation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showSoqlValidation: false,
      soql: props.field.expr,
      showUnappliedChangesDialog: false,
      draftCompilationResult: props.compilationResult,
      inputValues: [],
      transformResult: null
    };
  }

  soqlEditorCompile = (soql: string) => {
    this.setState({ soql: soql });
    this.compile(soql);
  };

  onClose = () => {
    // check if there are unapplied changes
    if (this.state.soql !== this.props.field.expr) {
      this.setState({ showUnappliedChangesDialog: true });
    } else {
      this.props.onDismiss();
    }
  };

  applyChanges = () => {
    this.props.onSoQLChange(this.state.soql || '');
    this.props.onDismiss();
  };

  closeUnappliedChangesDialog = () => this.setState({ showUnappliedChangesDialog: false });

  // We don't want to change the app state until we apply the changes made here (or we discard them and don't apply the changes)
  compile = (expr: string) => {
    const ref = uuid.v4();
    const field = { ...this.props.field, expr };

    debounceCompilation(() => {
      this.props.chan
        .push(
          'compile',
          {
            ref,
            field,
            qualifier: this.props.qualifier,
            template_name: this.props.template.name
          },
          TemplateChannelTimeout
        )
        .receive('ok', (result) => this.setState({ draftCompilationResult: some(result) }))
        .receive('error', (error) => console.error('unable to compile draft', error));
    });
  };

  evaluate = (value: Evaluatable) => {
    const { qualifier, template } = this.props;
    const field = { ...this.props.field, expr: this.state.soql || '' };
    this.setState({ inputValues: value });

    const paddedEvaluatable: Evaluatable = typedUniqColumnRefs(this.props.template, field).map((cref) => {
      const existing = value.find((ev) => isExpressionEqualIgnoringPosition(ev.columnRef, cref));
      return {
        columnRef: cref,
        value: _.isUndefined(existing) ? null : existing.value
      };
    });
    return new Promise<void>((resolve, reject) => {
      this.props.chan
        .push(
          'evaluate',
          {
            template_name: template.name,
            qualifier: qualifier,
            field,
            value: paddedEvaluatable
          },
          TemplateChannelTimeout
        )
        .receive('ok', ({ results }: { results: TransformResult[] }) => {
          // we're only ever sending one value to transform, so we only care about the first result that comes back
          this.setState({ transformResult: results[0] });
          resolve();
        })
        .receive('error', reject);
    });
  };

  render() {
    const dialogAttributes = new Map([
      ['aria-labelledby', 'validation-dialog-title'],
      ['aria-describedby', 'validation-dialog-subtitle']
    ]);

    const { scope, field, matcher, template, qualifier, enumeration, updateInputType } = this.props;
    const { soql, showSoqlValidation, draftCompilationResult, inputValues, transformResult } = this.state;

    const soqlEditorProps: SoQLEditorProps = {
      scope,
      soql: this.state.soql || '',
      compilationResult: draftCompilationResult,
      matcher,
      soqlMode: true,
      onChange: this.soqlEditorCompile,
      onChangeSelectedFunction: () => {},
      selectedFunction: none,
      height: '420px'
    };

    const fieldTesterProps: FieldTesterProps = {
      template,
      qualifier,
      field,
      inputValues,
      evaluate: this.evaluate,
      transformResult,
      compilationResult: draftCompilationResult,
      enumeration,
      updateInputType
    };

    const applyDisabled =
      soql === field.expr ||
      draftCompilationResult.isEmpty ||
      draftCompilationResult.get.type !== CompilationStatus.Succeeded;
    return (
      <ForgeDialog
        onDismiss={this.onClose}
        open={true}
        options={{ backdropClose: false, escapeClose: false, dialogAttributes }}
      >
        <div className="validation-field-dialog">
          <div className="validation-dialog-header">
            <ForgeToolbar>
              <h1
                slot="start"
                id="validation-dialog-title"
                className="forge-typography--title forge-header-title"
              >
                {t('title')}
              </h1>
              <ForgeIconButton slot="end">
                <button
                  data-testid="validation-modal-x"
                  className="tyler-icons"
                  onClick={this.onClose}
                  aria-label={t('close')}
                >
                  close
                </button>
              </ForgeIconButton>
            </ForgeToolbar>
          </div>
          <ForgeScaffold>
            <div slot="body-header">
              <h2
                id="validation-dialog-subtitle"
                className="forge-typography--subtitle1-secondary validation-field-body-header"
              >
                {t('sub_title')}
              </h2>
            </div>
            <div slot="body" className="validation-field-body">
              <div className="input-wrapper">
                <div>
                  <FieldTester {...fieldTesterProps} />
                </div>
                <ForgeExpansionPanel open={showSoqlValidation} data-testid="validation-expansion-panel">
                  <div>
                    <CompilerResult result={draftCompilationResult} />
                    <div className="editor-ace" data-testid="soql-editor">
                      <div>
                        <SoQLEditor {...soqlEditorProps} />
                      </div>
                      <div className="validation-soql-docs">
                        <SoQLDocs completer={matcher} selection={undefined} />
                      </div>
                    </div>
                  </div>
                </ForgeExpansionPanel>
              </div>
            </div>
          </ForgeScaffold>
          <div className="validation-dialog-footer">
            <ForgeToolbar inverted={true}>
              <ForgeButton
                slot="start"
                type="outlined"
                onClick={() => this.setState({ showSoqlValidation: !showSoqlValidation })}
              >
                <button type="button" data-testid="edit-soql-validation-button">
                  {this.state.showSoqlValidation ? (
                    <>
                      <ForgeIcon name="eye_off" />
                      {t('hide_validation')}
                    </>
                  ) : (
                    <>
                      <ForgeIcon name="cog" />
                      {t('edit_validation')}
                    </>
                  )}
                </button>
              </ForgeButton>

              <ForgeButton
                slot="end"
                type="outlined"
                onClick={this.onClose}
                className="validation-cancel-button"
              >
                <button type="button" data-testid="cancel-soql-validation-button">
                  {t('cancel')}
                </button>
              </ForgeButton>
              <ForgeButton slot="end" type="raised">
                <button
                  type="button"
                  data-testid="apply-soql-validation-button"
                  disabled={applyDisabled}
                  onClick={this.applyChanges}
                >
                  {t('apply')}
                </button>
              </ForgeButton>
            </ForgeToolbar>
          </div>
          {this.state.showUnappliedChangesDialog && (
            <ConfirmationModal
              agreeButtonVariant={VARIANTS.ERROR}
              agreeButtonText={t('modal_discard_changes')}
              onAgree={() => {
                this.closeUnappliedChangesDialog();
                this.props.onDismiss();
              }}
              onCancel={this.closeUnappliedChangesDialog}
              headerText={t('modal_title')}
              cancelButtonText={t('modal_cancel')}
            >
              {t('modal_body')}
            </ConfirmationModal>
          )}
        </div>
      </ForgeDialog>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    chan: state.channel
  };
};

export default connect(mapStateToProps)(FieldValidation);
