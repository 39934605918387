import { getBuiltins, isMBI1ValidatableBuiltin } from 'common/dsmapi/metadataTemplate';
import I18n from 'common/i18n';
import { FieldT, MetadataTemplate } from 'common/types/metadataTemplate';
import React from 'react';
import { option } from 'ts-option';
import { FieldChoice } from './FieldChoice';
import './fieldsets.scss';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates', ...options });

interface BuiltinFieldChooserProps {
  template: MetadataTemplate;
  onChooseField: (f: FieldT) => void;
  isSelected: (f: FieldT) => boolean;
}
export const BuiltinFieldChooser: React.FunctionComponent<BuiltinFieldChooserProps> = ({
  template,
  onChooseField,
  isSelected
}) => {
  const builtins = getBuiltins([template]);
  // if builtins ever become optional
  const getBuiltin = (fieldName: string) =>
    option(builtins.find((f) => f.fieldName === fieldName)).map((f) => f.instances[0]);
  const renderField = (fieldName: string) =>
    getBuiltin(fieldName)
      .map((f) => (
        <FieldChoice
          key={fieldName}
          isSelected={isSelected(f)}
          field={f}
          qualifier={null}
          onChooseField={() => onChooseField(f)}
        />
      ))
      .getOrElseValue(<span key={fieldName}></span>);

  const builtinNames = [
    'name',
    'description',
    'row_label',
    'contact_email',
    'category',
    'tags',
    'license_id',
    'attribution',
    'attribution_link'
  ];

  return <div>{builtinNames.filter(isMBI1ValidatableBuiltin).map(renderField)}</div>;
};
