import { fieldDisplayName, hasRequiredExprs } from 'common/dsmapi/metadataTemplate';
import I18n from 'common/i18n';
import { FieldT } from 'common/types/metadataTemplate';
import React from 'react';
import './fieldsets.scss';
import { ForgeIcon, ForgeListItem, ForgeTooltip } from '@tylertech/forge-react';
import { TableQualifier } from 'common/types/soql';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates', ...options });

interface FieldChoiceProps {
  field: FieldT;
  qualifier: TableQualifier;
  isSelected: boolean;
  onChooseField: () => void;
}

const titleElement = (field: FieldT, qualifier: TableQualifier) => (
  <>
    <span className="field-choice-display-name">{fieldDisplayName(field)} </span>
    <span className="field-card-required-option">{`(${t(
      hasRequiredExprs([field], qualifier) ? 'required' : 'optional'
    )})`}</span>
  </>
);

const trailingElement = (field: FieldT) => (
  field.private ? (
      <span className="field-choice-audience">
        <ForgeIcon name="lock" className="field-icon" />
        <ForgeTooltip>
          <span>{t('private')}</span>
        </ForgeTooltip>
      </span>
    ) : (
      <span className="field-choice-audience">
        <ForgeIcon className="field-icon" name="public" />
        <ForgeTooltip>
          <span>{t('public')}</span>
        </ForgeTooltip>
      </span>
    )
);

const dataTestId = 'field-choice-list-item';

export const fieldChoiceDragDrop = ({field, qualifier, isSelected, onChooseField}: FieldChoiceProps) => {
  const titleEl = titleElement(field, qualifier);
  const trailingEl = trailingElement(field);
  return {
    dragAndDroppable: true,
    element: titleEl,
    trailingElement: trailingEl,
    className: 'draggable-field-choice-list-item',
    additionalItemProps: {
      selected: isSelected,
      onClick: onChooseField,
      dataTestId
    }
  };
};

export const FieldChoice: React.FunctionComponent<FieldChoiceProps> = ({
  field,
  qualifier,
  isSelected,
  onChooseField
}) => {
  return (
    <ForgeListItem onClick={onChooseField} selected={isSelected} data-testid={dataTestId}>
      <div slot="title">
        {titleElement(field, qualifier)}
      </div>
      <div slot="trailing">
        {trailingElement(field)}
      </div>
    </ForgeListItem>
  );
};
