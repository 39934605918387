import React from 'react';
import { find as _find } from 'lodash';
import { ForgeSelect } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import {
  intoMetadataComponents,
  checkForParentChildRelationship,
  buildDefaultParentlessSelect,
  buildDefaultSelectWithSelectParent,
  buildDefaultMultiSelect,
  buildDefaultMultiSelectWithParent,
  buildDefaultSelectWithMultiSelectParent
} from 'common/dsmapi/metadataTemplate';
import { Expr, SoQLType, TableQualifier } from 'common/types/soql';
import { FieldT, labelOption, MetadataTemplate, MetadataType } from 'common/types/metadataTemplate';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates.parent_selector', ...options });

interface ParentFieldOption {
  qualifier: string;
  fieldName: string;
  displayName: string;
  options: string[];
  type: MetadataType;
}

export interface ParentSelectorProps {
  template: MetadataTemplate;
  field: FieldT;
  parsedExpression: Expr;
  qualifier: TableQualifier;
  updateExpr: ({
    expr,
    newLabels,
    newType
  }: {
    expr: Expr;
    newLabels?: labelOption[];
    newType?: SoQLType;
  }) => void;
}

const getPossibleParentFields = (
  template: MetadataTemplate,
  currentFieldName: string,
  currentFieldQualifier: TableQualifier,
  currentFieldType: MetadataType,
): ParentFieldOption[] => {
  const parentFieldOptions: ParentFieldOption[] = [];

  template.custom_fields.forEach(({ fields, fieldset_qualifier: qualifier }) => {
    if (qualifier === currentFieldQualifier) {
      fields.forEach(
        ({
          field_name: fieldName,
          display_name: displayName,
          parsed_expr: parsedExpression,
          labels_options
        }) => {
          if (fieldName !== currentFieldName) {
            intoMetadataComponents(qualifier, fieldName, parsedExpression, labels_options).match({
              none: () => null,
              some: (metadataComponents) => {
                const isChildFieldASelectForm =
                  currentFieldType === MetadataType.select ||
                  currentFieldType === MetadataType.dependentSelectWithSelectParent ||
                  currentFieldType === MetadataType.dependentSelectWithMultiSelectParent;

                const parentOptionHasAParent =
                  metadataComponents.type === MetadataType.dependentMultiSelect ||
                  metadataComponents.type === MetadataType.dependentSelectWithMultiSelectParent ||
                  metadataComponents.type === MetadataType.dependentSelectWithSelectParent;

                // We don't want to let the user make a parent-child loop
                const isNotAParentChildLoop = (() => {
                  if (parentOptionHasAParent) {
                    return metadataComponents.parentField.value !== currentFieldName;
                  }

                  return true; // No parent, no loop
                })();

                if (isNotAParentChildLoop) {
                  parentFieldOptions.push({
                    qualifier,
                    fieldName,
                    displayName,
                    options: metadataComponents.options,
                    type: metadataComponents.type
                  });
                }
              }
            });
          }
        }
      );
    }
  });

  return parentFieldOptions;
};

const buildDefaultMetadataExpressionWithParent = (
  childType: MetadataType,
  fieldName: string,
  displayName: string,
  qualifier: TableQualifier,
  newParent: ParentFieldOption
): Expr => {
  const isChildFieldASelectForm =
    childType === MetadataType.select ||
    childType === MetadataType.dependentSelectWithSelectParent ||
    childType === MetadataType.dependentSelectWithMultiSelectParent;

  if (isChildFieldASelectForm) {
    if (newParent.type === MetadataType.multiSelect || newParent.type === MetadataType.dependentMultiSelect) {
      return buildDefaultSelectWithMultiSelectParent(
        fieldName,
        displayName,
        qualifier,
        newParent.fieldName,
        newParent.displayName,
        newParent.options[0]
      );
    }

    return buildDefaultSelectWithSelectParent(
      fieldName,
      displayName,
      qualifier,
      newParent.fieldName,
      newParent.displayName,
      newParent.options[0]
    );
  }

  // If the child isn't a select, it must be a multiselect
  return buildDefaultMultiSelectWithParent(
    fieldName,
    displayName,
    qualifier,
    newParent.fieldName,
    newParent.displayName,
    newParent.options[0]
  );
};

const getCurrentParentField = (
  parsedExpression: Expr,
  currentFieldQualifier: TableQualifier,
  currentFieldName: string,
  labelsOptions: labelOption[]
) => {
  return checkForParentChildRelationship(
    parsedExpression,
    currentFieldQualifier,
    currentFieldName,
    labelsOptions
  ).match({
    none: () => null,
    some: (enumeration) => {
      return {
        qualifier: enumeration.parentField.qualifier,
        fieldName: enumeration.parentField.value
      };
    }
  });
};

const ParentSelector: React.FunctionComponent<ParentSelectorProps> = ({
  template,
  field,
  parsedExpression,
  qualifier,
  updateExpr
}) => {
  const { field_name: fieldName, display_name: displayName, labels_options: labelsOptions } = field;
  if (field.is_builtin) return null;
  return intoMetadataComponents(qualifier, fieldName, parsedExpression, field.labels_options).match({
    none: () => null,
    some: ({ type }) => {
      const parentFieldOptions = getPossibleParentFields(
        template,
        fieldName,
        qualifier,
        type
      );
      const currentParentField =
        getCurrentParentField(parsedExpression, qualifier, fieldName, labelsOptions)?.fieldName || '';

      const isOptionDisabled = (options: string[]) => !options.length || options[0] === '';

      const displayableParentFieldOptions = [
        { value: '', label: t('none_selected_option_label') },
        ...parentFieldOptions.map(({ displayName: parentDisplayName, fieldName: parentFieldName, options }) => {
          return { value: parentFieldName, label: parentDisplayName, disabled: isOptionDisabled(options) };
        })
      ];

      return (
        <div>
          <ForgeSelect
            label={t('parent_select_label')}
            value={currentParentField}
            options={displayableParentFieldOptions}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.value == '') {
                let newExpression: Expr;
                switch (type) {
                  case MetadataType.select:
                  case MetadataType.dependentSelectWithSelectParent:
                  case MetadataType.dependentSelectWithMultiSelectParent:
                    newExpression = buildDefaultParentlessSelect(fieldName, displayName, qualifier);
                    break;
                  case MetadataType.multiSelect:
                  case MetadataType.dependentMultiSelect:
                    newExpression = buildDefaultMultiSelect(fieldName, qualifier);
                }

                updateExpr({
                  expr: newExpression,
                  newLabels: []
                });
              } else {
                const newParent = _find(
                  parentFieldOptions,
                  ({ fieldName: optionFieldName }) => optionFieldName == event.target.value
                );
                // It's always gonna exist, this is just for typescript
                if (newParent) {
                  const newExpression = buildDefaultMetadataExpressionWithParent(
                    type,
                    fieldName,
                    displayName,
                    qualifier,
                    newParent
                  );

                  updateExpr({
                    expr: newExpression,
                    newLabels: []
                  });
                }
              }
            }}
            data-testid="parent-field-selector"
          >
            <span slot="helper-text">{t('parent_select_helper_text')}</span>
          </ForgeSelect>
        </div>
      );
    }
  });
};

export default ParentSelector;
