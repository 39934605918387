import { VARIANTS } from 'common/components/Button';
import ConfirmationModal from 'common/components/ConfirmationDialog/ConfirmationModal';
import Modal from 'common/components/Modal';
import { fieldDisplayName } from 'common/dsmapi/metadataTemplate';
import I18n from 'common/i18n';
import { FieldT, MetadataTemplate } from 'common/types/metadataTemplate';
import { isColumnRef, TableQualifier, traverseExpr } from 'common/types/soql';
import React, { useState } from 'react';
import { ForgeButton } from '@tylertech/forge-react/';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates', ...options });

interface Props {
  qualifier: TableQualifier;
  field: FieldT;
  template: MetadataTemplate;
  deleteField: () => void;
  busy: boolean;
}

const FieldDeletionButton: React.FunctionComponent<Props> = ({
  template,
  qualifier,
  field,
  busy,
  deleteField
}) => {
  const [showingConfirmationModal, setShowingConfirmationModal] = useState(false);
  const [showingBrokenRefsModal, setShowingBrokenRefsModal] = useState(false);

  const allFields = template.builtin_fields
    .map((builtin) => ({ qualifier: null as TableQualifier, field: builtin }))
    .concat(
      template.custom_fields.flatMap((fieldset) =>
        fieldset.fields.map((customField) => ({ qualifier: fieldset.fieldset_qualifier, field: customField }))
      )
    )
    .filter(
      (qualifiedField) =>
        // keep only the builtin fields that aren't  *this* field
        qualifiedField.qualifier !== qualifier || qualifiedField.field.field_name !== field.field_name
    );

  const brokenRefs = allFields.filter(({ field: otherField }) =>
    // for the remaining fields, keeping the field if it has an expression that
    // references the one we're about to delete anywhere
    traverseExpr(
      otherField.parsed_expr,
      false as boolean,
      (node, acc) =>
        (!!node && isColumnRef(node) && node.qualifier === qualifier && node.value === field.field_name) ||
        acc
    )
  );

  if (!field.is_builtin) {
    return (
      <>
        <ForgeButton className={'field-editor-buttons field-editor-delete'} data-testid='field-editor-delete-button'>
          <button
            disabled={busy}
            onClick={() => {
              if (brokenRefs.length > 0) {
                setShowingBrokenRefsModal(true);
              } else {
                setShowingConfirmationModal(true);
              }
            }}
          >{t('delete_field_base')}</button></ForgeButton>

        {showingBrokenRefsModal && (
          <Modal onDismiss={() => setShowingBrokenRefsModal(false)}>
            <p>{t('delete_conflict')}</p>

            <ul>
              {brokenRefs.map((qualifiedField) => (
                <li key={`${qualifiedField.qualifier}_${qualifiedField.field.field_name}`}>
                  {fieldDisplayName(qualifiedField.field)}
                </li>
              ))}
            </ul>

            <p>{t('remove_references', field)}</p>
          </Modal>
        )}
        {showingConfirmationModal && (
          <ConfirmationModal
            agreeButtonVariant={VARIANTS.ERROR}
            agreeButtonText={t('delete_field_base')}
            onAgree={() => {
              deleteField();
              setShowingConfirmationModal(false);
            }}
            onCancel={() => setShowingConfirmationModal(false)}
            headerText={t('delete_field_title', field)}
          >
            {t('delete_field_confirm_body')}
          </ConfirmationModal>
        )}
      </>
    );
  }
  return null;
};

export default FieldDeletionButton;
