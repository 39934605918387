import { uniqColumnRefs } from 'common/soql/soql-helpers';
import { FieldT, MetadataTemplate } from 'common/types/metadataTemplate';
import { TableQualifier } from 'common/types/soql';

export const typedUniqColumnRefs = (template: MetadataTemplate, field: FieldT) => {
  const typeLookup = template.builtin_fields
    .map((b) => ({
      qualifier: null as TableQualifier,
      fieldName: b.field_name,
      type: b.input_soql_type
    }))
    .concat(
      template.custom_fields.flatMap((fs) =>
        fs.fields.map((f) => ({
          qualifier: fs.fieldset_qualifier,
          fieldName: f.field_name,
          type: f.input_soql_type
        }))
      )
    );
  return uniqColumnRefs(field.parsed_expr).map((cref) => ({
    ...cref,
    soql_type: typeLookup.find((tl) => tl.qualifier === cref.qualifier && tl.fieldName === cref.value)!.type
  }));
};

// Giant timeout for EN-55474
export const TemplateChannelTimeout = 60000;
