import React from 'react';
import { connect } from 'react-redux';
import I18n from 'common/i18n';
import { ForgeTextField } from '@tylertech/forge-react';
import { ActionTypes, Dispatcher } from 'metadataTemplates/store';
import { FieldT, MetadataTemplate } from 'common/types/metadataTemplate';
import { TableQualifier } from 'common/types/soql';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates', ...options });

interface ExternalProps {
  template: MetadataTemplate;
  qualifier: TableQualifier;
  field: FieldT;
}
interface DispatchProps {
  updateDefaultValue: (defaultValue: string) => void;
}

type Props = ExternalProps & DispatchProps;

export const TextFieldDefaultValue: React.FunctionComponent<Props> = ({
  field,
  updateDefaultValue
}) => {
  const onDefaultValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateDefaultValue(event.target.value);
  };
  return (
    <>
      <h6><label htmlFor="input-test" slot="label">{t('default_value')}</label></h6>
      <ForgeTextField>
        <input
          type="text"
          id="input-text"
          value={field.default_value || ''}
          onChange={onDefaultValueChange}
          data-testid='field-editor-default-testbox'
        />
      </ForgeTextField>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatcher, props: ExternalProps): DispatchProps => {
  return {
    updateDefaultValue: (defaultValue: string) => {
      dispatch({
        type: ActionTypes.UpdateFieldDefaultValue,
        templateName: props.template.name,
        qualifier: props.qualifier,
        field: props.field,
        defaultValue
      });
    },
  };
};

export default connect(null, mapDispatchToProps)(TextFieldDefaultValue);
