import React from 'react';
import { SoQLType } from 'common/types/soql';
import { ForgeSelect } from '@tylertech/forge-react';
import { IOption } from '@tylertech/forge/esm/select';

interface Props {
  type: SoQLType;
  onSelection: (st: SoQLType) => void;
  disabled?: boolean;
}

export function InputTypeDropdownForged({ type, onSelection, disabled }: Props) {
  const options: IOption[] = [
    { label: 'Text', value: SoQLType.SoQLTextT },
    { label: 'Json', value: SoQLType.SoQLJsonT }
  ];
  return (
    <ForgeSelect
      options={options}
      value={type}
      disabled={disabled}
      onChange={(o: CustomEvent) => onSelection(o.detail)}
      data-testid="field-input-type-dropdown"
    />
  );
}
