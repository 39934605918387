import React from 'react';
import I18n from 'common/i18n';
import FieldWithParentEditor from './FieldWithParentEditor';
import { FieldT, labelOption, OptionByParent } from 'common/types/metadataTemplate';
import { Binding, ColumnRef, Expr, FunCall, Let, SoQLBooleanLiteral } from 'common/types/soql';
import { buildDefaultSelectWithMultiSelectParent } from 'common/dsmapi/metadataTemplate';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates', ...options });

export interface SelectWithMultiSelectParentEditorProps {
  field: FieldT;
  fieldColumn: ColumnRef;
  parentField: ColumnRef;
  optionsByParent: OptionByParent[];
  parentOptions: string[];
  parentLabels: labelOption[];
  parentDisplayName: string;
  withLabels: boolean;
  updateExpr: ({ expr, newLabels }: { expr: Expr; newLabels?: labelOption[] }) => void;
  updateLegacyLabels: (labelsOptions: labelOption[]) => void;
  defaultPredicate: SoQLBooleanLiteral;
  staticCases: Expr[];
}
const SelectWithMultiSelectParentEditor: React.FunctionComponent<SelectWithMultiSelectParentEditorProps> = ({
  field,
  fieldColumn,
  parentField,
  optionsByParent,
  parentOptions,
  parentLabels,
  parentDisplayName,
  withLabels,
  updateExpr,
  updateLegacyLabels,
  defaultPredicate,
  staticCases
}) => {
  const qualifier = fieldColumn.qualifier;

  const makeNewSelectWithMultiSelectParent = (newOptionsByParent: OptionByParent[]): Let => {
    const validOptionCases: Array<FunCall> = newOptionsByParent.map((optionByParent) => ({
      type: 'funcall',
      function_name: 'case',
      window: null,
      args: [
        {
          type: 'funcall',
          function_name: 'json_array_contains',
          window: null,
          args: [
            parentField,
            {
              type: 'string_literal',
              value: optionByParent.parentValue
            }
          ]
        },
        {
          type: 'funcall',
          function_name: 'cast$json',
          window: null,
          args: [
            {
              type: 'string_literal',
              value: JSON.stringify(optionByParent.options)
            }
          ]
        },
        {
          type: 'boolean_literal',
          value: true
        },
        {
          type: 'funcall',
          function_name: 'cast$json',
          window: null,
          args: [
            {
              type: 'string_literal',
              value: '[]'
            }
          ]
        }
      ]
    }));

    // Default empty array to make sure that combine_json_arrays is always given at least one argument
    validOptionCases.push({
      type: 'funcall',
      function_name: 'cast$json',
      window: null,
      args: [
        {
          type: 'string_literal',
          value: '[]'
        }
      ]
    });

    const clauses: Array<Binding> = [
      {
        type: 'binding',
        qualifier: null,
        name: 'valid_options',
        expr: {
          type: 'funcall',
          function_name: 'combine_json_arrays',
          window: null,
          args: validOptionCases
        }
      }
    ];

    const newErrorMessage = t('error_messages.select_with_multi_select_parent_error_message', {
      name: field.display_name
    });

    const newDefaultConsequent: FunCall = {
      type: 'funcall',
      function_name: 'error',
      window: null,
      args: [
        {
          type: 'funcall',
          function_name: 'op$||',
          window: null,
          args: [
            {
              type: 'string_literal',
              value: newErrorMessage
            },
            {
              type: 'funcall',
              function_name: 'cast$text',
              window: null,
              args: [
                {
                  type: 'column_ref',
                  value: 'valid_options',
                  qualifier: null
                }
              ]
            }
          ]
        }
      ]
    };

    const body: FunCall = {
      type: 'funcall',
      function_name: 'case',
      window: null,
      args: [...staticCases, defaultPredicate, newDefaultConsequent]
    };

    return {
      type: 'let',
      body,
      clauses
    };
  };

  return (
    <FieldWithParentEditor
      field={field}
      qualifier={qualifier}
      parentField={parentField}
      parentDisplayName={parentDisplayName}
      parentOptions={parentOptions}
      parentLabels={parentLabels}
      optionsByParent={optionsByParent}
      withLabels={withLabels}
      updateLegacyLabels={updateLegacyLabels}
      makeNewFieldExpression={makeNewSelectWithMultiSelectParent}
      buildDefaultFieldExpression={buildDefaultSelectWithMultiSelectParent}
      updateExpr={updateExpr}
    />
  );
};

export default SelectWithMultiSelectParentEditor;
