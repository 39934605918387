/* eslint-disable @typescript-eslint/no-shadow */
// ffs eslint it's not shadowing if it's keys in an enuam,
// the keys are namespaced by the enum name
import I18n from 'common/i18n';
import * as Dsmapi from 'common/types/dsmapi';
import { MetadataTemplateWithDeprecatedLegacyLabels } from 'common/types/metadataTemplate';
import { FunSpec } from 'common/types/soql';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { browserHistory, Route, IndexRoute, Router } from 'react-router';
import Audit from './components/Audit';
import Categories from './components/Categories';
import FieldSets from './components/FieldSets';
import init, { ActionTypes } from './store';
import './style.scss';
import { TemplateChannelTimeout } from './util';
import { ForgeTab, ForgeTabBar, ForgeView, ForgeViewSwitcher } from '@tylertech/forge-react';
import { ITabBarActivateEventData } from '@tylertech/forge/esm/tabs';
import { getSiteChromeFooter, getSiteChromeHeight } from 'common/util/siteMeasurements';
import { convertLegacyLabelsTemplateToLabelOptionsTemplate } from 'common/dsmapi/metadataTemplate';

const t = (k: string, options: { [key: string]: any } = {}) => I18n.t(k, { scope: 'metadata_templates', ...options });

enum Tabs {
  Categories = 'categories',
  CustomFields = 'custom_fields',
  Audit = 'audit'
}

const tabIndexes = {
  [Tabs.Categories]: 0,
  [Tabs.CustomFields]: 1,
  [Tabs.Audit]: 2
};

const indexedTabs = {
  0: [Tabs.Categories],
  1: [Tabs.CustomFields],
  2: [Tabs.Audit]
};

interface BodyProps {
  tab: Tabs
}

const onTabClick = (tabIndex: number) => {
  browserHistory.push(`/admin/metadata_templates/${indexedTabs[tabIndex]}`);
};

const getViewHeight = () => {
  const siteChromeHeaderHeight = getSiteChromeHeight();
  const adminHeader = 42; // this the the 'Administration > Metadata' header height
  const tabBarHeight = 48;
  const siteChromeFooterHeight = getSiteChromeFooter();

  const totalOffSet = siteChromeHeaderHeight + adminHeader + tabBarHeight + siteChromeFooterHeight;
  return { height: `calc(100vh - ${totalOffSet}px)` };
};

const Body: React.FunctionComponent<BodyProps> = ({ tab, children }) => {
  return (
    <div>
      <div>
        <ForgeTabBar className="metadata-templates-tab-bar" underline layoutMode={'clustered'} activeTab={tabIndexes[tab]} on-forge-tab-bar-activate={(event: CustomEvent<ITabBarActivateEventData>) => onTabClick(event.detail.index)}>
          <ForgeTab data-testid='categories-tab'>{t('tab_categories')}</ForgeTab>
          <ForgeTab data-testid='field-tab'>{t('tab_fields')}</ForgeTab>
          <ForgeTab data-testid='audit-tab'>{t('tab_audit')}</ForgeTab>
        </ForgeTabBar>
      </div>
      <ForgeViewSwitcher index={tabIndexes[tab]}>
        <ForgeView style={getViewHeight()}>
          <div className="metadata-body">
            {children}
          </div>
        </ForgeView>
      </ForgeViewSwitcher>
    </div>
  );
};

const socket = Dsmapi.socket();
const chan = socket.channel('edit_metadata_templates');
const store = init(chan);


chan.on('scope', ({ scope }: { scope: FunSpec[] }) => {
  store.dispatch({ type: ActionTypes.ScopeChange, scope });
});
chan.join(TemplateChannelTimeout)
  .receive('ok', ({ templates }: { templates: MetadataTemplateWithDeprecatedLegacyLabels[] }) => {
    const convertedTemplates = templates.map(t => convertLegacyLabelsTemplateToLabelOptionsTemplate(t));
    store.dispatch({ type: ActionTypes.TemplatesChange, templates: convertedTemplates });
    store.dispatch({ type: ActionTypes.ChannelConnected });
  })
  .receive('error', (e) => {
    console.error(e);
    store.dispatch({ type: ActionTypes.ChannelDisconnected });
  });


ReactDOM.render(
  <Provider store={store}>
    <Router history={browserHistory}>
      <Route path="/(:locale/)admin/metadata_templates">
        <IndexRoute component={() => <Body tab={Tabs.Categories}><Categories /></Body>} />
        <Route path={Tabs.Categories} component={() => <Body tab={Tabs.Categories}><Categories /></Body>} />
        <Route path={Tabs.CustomFields} component={() => <Body tab={Tabs.CustomFields}><FieldSets /></Body>} />
        <Route path={Tabs.Audit} component={() => <Body tab={Tabs.Audit}><Audit /></Body>} />
      </Route>
    </Router>
  </Provider>,
  document.querySelector('#metadata-templates-main')
);
